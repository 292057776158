import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { CUSTOMER_ADMIN, CUSTOMER_USER, SUPER_ADMIN, joinQueryParam, refreshTime } from '../apis/apis';
import { checkEmpty } from '../utils/utils';
import credentials, { baseUrl } from '../apis/credentials';

import axios from 'axios';

function removeNullParams(params = {}) {
  let validParam = {};
  for (let key in params) {
    if (params[key] !== null) {
      validParam[key] = params[key];
    }
  }
  return validParam;
}

export function useFetchFun(fn = () => {}, params = { page: 0, per_page: 2000 }, realtime = false) {
  const { user_type, company, id } = useUserInfo();
  const options = removeNullParams(params);
  const queryInit = useMemo(() => {
    if (user_type === SUPER_ADMIN) return { ...options };
    else if (user_type === CUSTOMER_ADMIN) return { ...options, company_id: company.id };
    return { ...options, company_id: company.id, user_id: id };
  }, [user_type, company, id, options]);

  const [queryObject, updateQueryObject] = useState(queryInit);

  let fetcher = useCallback(
    (parm) => {
      return fn({ ...parm });
    },
    [fn]
  );

  useEffect(() => {
    fetcher(queryObject);
    let event = 0;
    if (realtime) {
      event = setInterval(() => {
        fetcher(queryObject);
      }, refreshTime);
    }
    return () => clearInterval(event);
  }, [queryObject, fetcher, realtime]);

  const resetQuery = React.useCallback(() => {
    updateQueryObject(queryInit);
  }, [queryInit]);

  return [queryObject, updateQueryObject, resetQuery];
}

export function useQueryParm(params = { page: 0, per_page: 2000 }) {
  const { user_type, company, id } = useUserInfo();
  const options = removeNullParams(params);

  const queryInit = useMemo(() => {
    if (user_type === SUPER_ADMIN) return { ...options };
    else if (user_type === CUSTOMER_ADMIN) return { ...options, company_id: company.id };
    return { ...options, company_id: company.id, user_id: id };
  }, [user_type, company, id, options]);

  const [queryObject, updateQueryObject] = useState(queryInit);

  const dispatch = useCallback((newParam = {}) => {
    updateQueryObject({ ...newParam });
  }, []);
  return [queryObject, dispatch];
}

export function debounce(fn, delay = 200) {
  let timer;
  return (parm) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      fn(parm);
    }, delay);
  };
}

export function useUserInfo() {
  const { user } = useSelector((state) => state.userInfo) || {};

  const myInfo = React.useMemo(() => {
    const defaultUser = {
      user_type: CUSTOMER_USER,
      description: '',
      company: {},
      id: '',
      feature: [],
      first_name: '',
      last_name: '',
      email: '',
    };
    return checkEmpty(user.data) ? defaultUser : user.data;
  }, [user]);

  const { user_type, company, id, feature, email, first_name, last_name, description } = myInfo;
  const isAuthenticate = () => !!id;
  return { user_type, company, id, feature, email, first_name, last_name, description, isAuthenticate };
}

export function useDimension() {
  const [dimension, setDimension] = useState({ x: window.innerWidth, y: window.innerHeight });

  const listener = debounce((e) => {
    setDimension({ x: e.target.innerWidth, y: e.target.innerHeight });
  });

  useEffect(() => {
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [listener]);

  return dimension;
}

export function useDarkMode() {
  const theme = localStorage.getItem('theme');
  const [dark, setDark] = useState(theme === 'dark' ? true : false);
  const darkToggle = useCallback(() => {
    setDark(!dark);
  }, [dark]);

  useEffect(() => {
    if (dark) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [dark]);
  return [dark, darkToggle];
}

export function useExpandedList(initId) {
  const [expandedList, updateExpandedList] = useState([initId]);

  const handleExpandedList = (id) => {
    if (expandedList.includes(id)) {
      if (expandedList.length > 1) {
        updateExpandedList((state) => state.filter((it) => it !== id));
      }
    } else {
      updateExpandedList([...expandedList, id]);
    }
  };

  return [expandedList, handleExpandedList];
}

export function useFilter(initParams = {}) {
  const [selectedParams, updateParams] = useState(initParams);
  const addParams = React.useCallback((params = {}) => {
    updateParams((state) => ({ ...state, ...params }));
  }, []);

  const resetFilter = React.useCallback(() => {
    updateParams(initParams);
  }, [initParams]);

  // const removeParams = React.useCallback(
  //   (paramKeys = ['']) => {
  //     const prevObj = { ...selectedParams };
  //     for (const key in paramKeys) {
  //       delete prevObj[key];
  //     }
  //     updateParams(prevObj);
  //   },
  //   [selectedParams]
  // );

  return [selectedParams, addParams, resetFilter];
}

export function useFormData(initFormData = {}) {
  const [formData, updateFormData] = useState(initFormData);
  const [error, updateError] = useState({});

  const handleChange = React.useCallback((fieldName = '', data = {}) => {
    updateFormData((state) => ({ ...state, [fieldName]: { ...data } }));
  }, []);

  const handleSubmit = (resolve = () => {}) => {
    const err = {};
    let submittableData = {};
    for (const key in formData) {
      const { value = '', required = false, error = false, altKey = '' } = formData[key];
      submittableData[key] = value;

      if (!value && required) {
        let errMsg = `${altKey || capitalize(key).split('_').join(' ')} is required`;
        err[key] = errMsg;
        updateFormData((state) => ({ ...state, [key]: { ...state[key], error: errMsg } }));
      } else if (error) {
        err[key] = error;
      }
    }

    if (!Object.keys(err).length) {
      resolve({ status: true, data: submittableData });
    } else {
      updateError(err);
      console.log('Error', err);
    }
  };
  const resetForm = React.useCallback(() => {
    updateFormData(initFormData);
  }, [initFormData]);

  return { formData, error, handleChange, handleSubmit, resetForm };
}

function capitalize(str) {
  let newStr = str.slice(0, 1).toUpperCase() + str.slice(1);
  return newStr;
}

export function useTab(tabs = [], initialIndex = 0) {
  const [currentTab, setCurrentTab] = useState(tabs[initialIndex]);
  const componentsWrapper = currentTab.component ? (
    <div>
      {tabs?.map((el, i) => (
        <div className={`${el.id === currentTab.id ? 'block' : 'hidden'}`} key={i}>
          {el.component || ''}
        </div>
      ))}
    </div>
  ) : null;

  return { currentTab, setCurrentTab, tabs, componentsWrapper };
}

export function useApiSlice(query = {}) {
  // const { user_type, company, id } = useUserInfo();
  // const options = removeNullParams(query);

  // const queryInit = useMemo(() => {
  //   if (user_type === SUPER_ADMIN) return { ...options };
  //   else if (user_type === CUSTOMER_ADMIN) return { ...options, company_id: company.id };
  //   return { ...options, company_id: company.id, user_id: id };
  // }, [user_type, company, id, options]);

  const [queryObject, updateQueryObject] = useState(query);
  const [promise, updatePromise] = useState({ isLoading: true, isError: false, result: { data: [], pagination: {} } });
  const getItems = React.useCallback(
    async ({ endPoint = '' }) => {
      const options = removeNullParams(queryObject);
      const makeUrl = `${baseUrl}/${endPoint}?${joinQueryParam(options)}`;
      try {
        const response = await axios({ url: makeUrl, method: 'GET', headers: credentials.getHeaders() });
        if (response.status) {
          const { status, ...restObject } = response.data;
          updatePromise((state) => ({ ...state, isLoading: false, isError: false, result: { ...restObject } }));
        } else {
          throw new Error({ err: response.data });
        }
      } catch (error) {
        updatePromise((state) => ({ ...state, isLoading: false, isError: { ...error } }));
      }
    },
    [queryObject]
  );

  const delItems = React.useCallback(
    async ({ ids = [], endPoint = '' }) => {
      const makeUrl = `${baseUrl}/${endPoint}/${ids.join(',')}`;
      try {

        const response = await axios({ url: makeUrl, method: 'DELETE', headers: credentials.getHeaders() });
        if (response.status) {
          // const { status, ...restObject } = response.data;
          const { data: prevData = [], pagination } = promise.result;
          const deletedItems = prevData?.filter((it) => !ids.includes(it.id));
          updatePromise((state) => ({ ...state, isLoading: false, isError: false, result: { data: deletedItems, pagination } }));
        } else {
          throw new Error({ err: response.data });
        }
      } catch (error) {
        updatePromise((state) => ({ ...state, isLoading: false, isError: { ...error } }));
      }
    },
    [promise]
  );

  const postItem = React.useCallback(
    async ({ endPoint = '', data = {} }) => {
      const makeUrl = `${baseUrl}/${endPoint}`;
      try {
        const response = await axios({ url: makeUrl, method: 'POST', data: data, headers: credentials.getHeaders() });
        if (response.status) {
          // const { status, ...restObject } = response.data;
          const { data: prevData = [], pagination = {} } = promise.result;
          updatePromise((state) => ({ ...state, isLoading: false, isError: false, result: { data: [...prevData, data], pagination } }));
        } else {
          throw new Error({ err: response.data });
        }
      } catch (error) {
        updatePromise((state) => ({ ...state, isLoading: false, isError: { ...error } }));
      }
    },
    [promise]
  );

  const updateItem = React.useCallback(
    async ({ endPoint = '', data = {} }) => {
      const makeUrl = `${baseUrl}/${endPoint}`;
      try {
        const response = await axios({ url: makeUrl, method: 'UPDATE', data: data, headers: credentials.getHeaders() });
        if (response.status) {
          // const { status, ...restObject } = response.data;
          const { data: prevData = [], pagination } = promise.result;
          const updatedData = prevData?.map((it) => (it.id === data.id ? { ...it, ...data } : it)) || data;
          updatePromise((state) => ({ ...state, isLoading: false, isError: false, result: { data: updatedData, pagination } }));
        } else {
          throw new Error({ err: response.data });
        }
      } catch (error) {
        updatePromise((state) => ({ ...state, isLoading: false, isError: { ...error } }));
      }
    },
    [promise]
  );
  return { query: queryObject, updateQuery: updateQueryObject, ...promise, getItems, delItems, postItem, updateItem };
}
