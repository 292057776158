import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as LeftArrow } from '../../../assets/icons/arrowLeft.svg';
import { useLocation } from 'react-router-dom';

export default function HomeTile({ label, children: Children }) {
  const location = useLocation();
  const history = window.history;

  return (
    <div className='flex items-center'>
      {location.pathname !== '/' && <LeftArrow strokeWidth={1.3} onClick={() => history.back()} className='w-4 cursor-pointer stroke-path' />}
      {Children ? Children : <h4 className='ml-1 flex-1'>{label}</h4>}
    </div>
  );
}

HomeTile.propTypes = {
  label: PropTypes.string,
};
