import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './dropDown.css';

export default function DropDown({ item, children, className = '', bodyClass = '' }) {
  const { id = '', title = '', leftIcon = () => null, rightIcon = () => null, subList = [] } = item;
  const [expandedIds, updateIds] = useState([]);
  const parentDiv = useRef();
  const headerRef = useRef();
  const location = useLocation();

  const removeId = React.useCallback(() => {
    updateIds(() => expandedIds.filter((it) => it !== id));
  }, [id]);

  const handleClick = () => {
    if (expandedIds.includes(id)) {
      removeId();
    } else {
      updateIds([...expandedIds, id]);
    }
  };

  const eventFun = React.useCallback(
    (e) => {
      if (parentDiv.current && headerRef.current) {
        if (!headerRef.current.contains(e.target) && !parentDiv.current.contains(e.target)) {
          updateIds(() => expandedIds.filter((it) => it !== id));
        }
      }
    },
    [expandedIds, id]
  );

  useEffect(() => {
    window.addEventListener('click', eventFun);
    return () => window.removeEventListener('click', eventFun);
  }, [eventFun]);

  useEffect(() => {
    removeId();
  }, [location, removeId]);

  return (
    <div className={`drop-down-mobile ${className}`}>
      <ul ref={headerRef} onClick={handleClick} className={`flex items-center h-6 w-full cursor-pointer font-medium text-primary text-md ${expandedIds.includes(id) && 'expanded'}`}>
        {leftIcon() && <li className='mr-2'>{leftIcon()}</li>}
        <li className='text-ellipsis whitespace-nowrap overflow-hidden'>{title}</li>
        {rightIcon() && <li>{rightIcon()}</li>}
      </ul>
      <div ref={parentDiv} className={`body ${bodyClass} ${expandedIds.includes(id) && 'expanded'}`}>
        {children ? (
          children
        ) : (
          <ul>
            {subList?.map((item) => (
              <li key={item.id} className='text-base cursor-pointer w-full font-normal text-primary'>
                {item.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

DropDown.propTypes = {
  item: PropTypes.object.isRequired,
};
